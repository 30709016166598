import classNames from 'classnames';
import { Icon } from '@/components/standalone';
import { useRef } from 'react';
import useOnScreen from '@/helpers/useOnScreen';
import globalPropTypes from '@/components/globalPropTypes';
import Link from 'next/link';
import styles from './AuditSection.module.scss';

function AuditSection() {
  const headingRef = useRef();
  const { isOnScreen: isHeadingOnScreen } = useOnScreen({
    ref: headingRef,
    threshold: 0.3,
    rootMargin: '0px',
    isOnce: true,
  });

  const auditorCardRef = useRef();
  const { isOnScreen: isAuditorCardOnScreen } = useOnScreen({
    ref: auditorCardRef,
    threshold: 0.3,
    rootMargin: '0px',
    isOnce: true,
  });

  const platformCardRef = useRef();
  const { isOnScreen: isPlatformCardOnScreen } = useOnScreen({
    ref: platformCardRef,
    threshold: 0.3,
    rootMargin: '0px',
    isOnce: true,
  });

  const projectCardRef = useRef();
  const { isOnScreen: isProjectCardOnScreen } = useOnScreen({
    ref: projectCardRef,
    threshold: 0.3,
    rootMargin: '0px',
    isOnce: true,
  });

  return (
    <div
      className={classNames(styles.auditSectionWrapper, 'section')}
      id="features"
    >
      <div className={classNames('max-content-width', styles.auditSection)}>
        <div
          className={classNames({
            [styles.auditSectionHeadingAppear]: isHeadingOnScreen,
          })}
          ref={headingRef}
        >
          <div className="tb-tag">
            <Icon name="Repeat2" />
            360° web3 security vision
          </div>
          <h2>Built for everyone</h2>
          <p>
            Audit firms publish their audits on Trustblock.
            <br /> We make them smart & reactive objects usable by everyone.
          </p>
        </div>
        <div>
          <Link
            className={classNames(styles.card, {
              [styles.cardAppear]: isPlatformCardOnScreen,
            })}
            href="/sec-api"
            ref={platformCardRef}
            id="platform-card"
          >
            <div>
              <div className={styles.cardLabel}>
                <Icon name="Blocks" />
                Security Data API
              </div>
              <h3 className={styles.cardTitle}>
                Build your product with security data
              </h3>
              <div className={styles.cardContent}>
                Use our web3 security data API & oracle to improve your UX &
                connect critical data to your contracts.
              </div>
            </div>
          </Link>
          <Link
            className={classNames(styles.card, {
              [styles.cardAppear]: isAuditorCardOnScreen,
            })}
            href="/auditors"
            ref={auditorCardRef}
            id="auditor-card"
          >
            <div>
              <div className={styles.cardLabel}>
                <Icon name="ShieldCheck" />
                Auditors
              </div>
              <h3 className={styles.cardTitle}>
                Publish your audits seamlessly
              </h3>
              <div className={styles.cardContent}>
                Build your reputation with insightful business metrics & publish
                your audits seamlessly for free.
              </div>
            </div>
          </Link>
          <Link
            className={classNames(styles.card, {
              [styles.cardAppear]: isProjectCardOnScreen,
            })}
            href="https://app.trustblock.run/auditors"
            ref={projectCardRef}
            id="project-card"
          >
            <div>
              <div className={styles.cardLabel}>
                <Icon name="ToyBrick" />
                Projects
              </div>
              <h3 className={styles.cardTitle}>Find your next auditor now</h3>
              <div className={styles.cardContent}>
                Get rid of the word-of-mouth based search & find an auditor that
                truly matches your project&apos;s needs.
              </div>
            </div>
          </Link>
          <Link
            className={classNames(styles.card, {
              [styles.cardAppear]: isProjectCardOnScreen,
            })}
            href="https://app.trustblock.run/"
            ref={projectCardRef}
            id="project-card"
          >
            <div>
              <div className={styles.cardLabel}>
                <Icon name="User" />
                Users
              </div>
              <h3 className={styles.cardTitle}>
                Check what&apos;s really secured
              </h3>
              <div className={styles.cardContent}>
                Easily navigate through web3&apos;s security, finally get a full
                security view for the projects you&apos;re invested in.
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

AuditSection.propTypes = globalPropTypes;
export default AuditSection;
